<template>
    <div>
        <Carousel />
        <div class="py-5">
            <Welcome />
            <Expertise />
            <Features />
            <YandexMap />
        </div>
    </div>
</template>

<script setup>
import { useHead } from "@unhead/vue"

useHead({
    title: "SFR Chain | Россия",
    meta: [
        {
            name: "title",
            content: "SFR Chain - Россия | качество, производительность, доступность | Цепи в наличии и под заказ с доставкой по всей России"
        },
        {
            name: "description",
            content: "Цепи SFR - уникальные цепи для гоночных, мотокроссовых и городских мотоциклов. Обеспечивая надежное качество, наши цепи гарантируют максимальную производительность на любой дороге. С использованием современных технологий и высококачественных материалов, наши цепи обеспечивают долгий срок службы, безопасность и комфорт в каждой поездке. Доступные цены, доставка по всей России, цепи в наличии и под заказ."
        }
    ]
})

import Carousel from "@/components/Home/Carousel"
import Welcome from "@/components/Home/Welcome.vue"
import Expertise from "@/components/Home/Expertise.vue"
import Features from "@/components/Common/Features"
import YandexMap from "@/components/Common/YandexMap"
</script>