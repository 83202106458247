<template>
    <div class="features my-5">
        <div class="container-xl">
            <div class="row row-cols-1 row-cols-md-4 g-3 g-lg-0 justify-content-between">
                <div class="col text-center py-3">
                    <div id="countUp_0" class="countUp" data-end="750">
                        <span class="features-text">750+</span>
                    </div>
                    <h4>
                        сотрудников
                    </h4>
                </div>
                <div class="col text-center py-3">
                    <div id="countUp_1" class="countUp" data-end="5000">
                        <span class="features-text">5000+</span>
                    </div>
                    <h4>
                        наименований продукции
                    </h4>
                </div>
                <div class="col text-center py-3">
                    <div id="countUp_2" class="countUp" data-end="150">
                        <span class="features-text">150+</span>
                    </div>
                    <h4>
                        патентов
                    </h4>
                </div>
                <div class="col text-center py-3">
                    <div id="##countUp_3" class="countUp" data-end="80">
                        <span class="features-text">80+</span>
                    </div>
                    <h4>
                        стран
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.features {
    padding: 60px 0;
    background-color: rgb(245, 246, 240);

    .features-text {
        font-size: 60px;
        color: #f18200;
    }
}

</style>