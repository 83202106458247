<template>
    <section class="footer" id="footer">
        <div class="container-xl py-5">
            <div class="row row-cols-1 row-cols-md-2 g-3 text-center">
                <div class="col col-md-5">
                    <h2 class="mb-3">Мы на маркетплейсах</h2>
                    <p class="my-2 py-1">
                        <a
                            class="hover:animate-pulse"
                            href="https://www.ozon.ru/seller/twl-141103/avtotovary-8500/sfr-87312103/?miniapp=seller_141103&opened=brand"
                            target="_blank"
                        >
                            <img src="../../../src/assets/images/ozon-footer.svg"/>
                        </a>
                    </p>
                    <p class="my-2 py-1">
                        <a
                            class="hover:animate-pulse"
                            href="https://www.wildberries.ru/seller/143021?sort=popular&page=1&xsubject=6540"
                            target="_blank"
                        >
                            <img src="../../../src/assets/images/WB-footer.svg"/>
                        </a>
                    </p>
                </div>
                <div class="col col-md-7">
                    <h2 class="mb-3">Контакты</h2>
                    <div class="d-flex justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="footer-ico mx-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        <p class="my-1 py-2">Россия, г. Иркутск</p>
                    </div>

                    <div class="d-flex justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="footer-ico mx-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                        </svg>

                        <p class="my-1 py-2">
                            <a class="text-black text-decoration-none" href="tel:88004445342">8 800 444 53 42</a>
                        </p>
                    </div>

                    <div class="d-flex justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="footer-ico mx-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>

                        <p class="my-1 py-1">
                            <a class="text-black text-decoration-none" href="mailto:info-tweel.pro@yandex.ru?subject=Письмо с сайта sfrchain.ru">info-tweel.pro@yandex.ru</a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.footer {
    background-color: #f0f0f0;
    .footer-ico {
        width: 20px;
    }
}
</style>