<template>
    <div id='carouselExampleIndicators' class='carousel slide mb-3' data-bs-ride='carousel'>
        <div class='carousel-indicators'>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='0'
                    class='custom-indicator active' aria-current='true' aria-label='Slide 1'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='1'
                    class='custom-indicator'
                    aria-label='Slide 2'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='2'
                    class='custom-indicator'
                    aria-label='Slide 3'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='3'
                    class='custom-indicator'
                    aria-label='Slide 4'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='4'
                    class='custom-indicator'
                    aria-label='Slide 5'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='5'
                    class='custom-indicator'
                    aria-label='Slide 6'>
            </button>
            <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='6'
                    class='custom-indicator'
                    aria-label='Slide 7'>
            </button>
        </div>
        <div class='carousel-inner'>
            <div class='carousel-item active position-relative' data-bs-interval='4000'>
                <img src='../../assets/images/motorcycle-chain.webp' class='hero-img d-block w-100'
                     alt='Спортивный мотоцикл на гоночной трассе'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                        <h2 class='carousel-item-title fw-bold text-white py-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Ведущий производитель цепей для мотоциклов с 1991 года
                        </h2>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/reinforced.webp' class='hero-img d-block w-100' alt='Мотоцикл с усиленной цепью'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Усиленная цепь
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            конструкция для больших нагрузок, более высокая прочность, хорошая износостойкость
                        </h4>
                    </div>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/professional.webp' class='hero-img d-block w-100'
                     alt='Мотоцикл со сверхусиленной цепью'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Сверхусиленная цепь
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            Использование бесшовной втулки, улучшенный процесс сплавления, более высокая прочность
                            конструкции
                        </h4>
                    </div>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/o-ring_x-ring.webp' class='hero-img d-block w-100'
                     alt='Мотоцикл с усиленными цепями типов O-Ring и X-Ring'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Усиленные цепи O-Ring / X-Ring <br class="d-none d-md-block">
                            H-O / H-X / 520-VO
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            множественные уплотнения, уменьшенный крутящий момент соединения, устойчивость к отложениям,
                            подходит для высокоскоростной езды на большие расстояния
                        </h4>
                    </div>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/super-x-ring.webp' class='hero-img d-block w-100'
                     alt='Мотоцикл со сверхусиленными цепями типов O-Ring и X-Ring'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Сверхусиленные цепи O-Ring / X-Ring <br class="d-none d-md-block">
                            SH-O / SH-X
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            сверхвысокая прочность, сверхузкая конструкция, сверхнизкое энергопотребление
                        </h4>
                    </div>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/timing.webp' class='hero-img d-block w-100'
                     alt='Цепь ГРМ'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Цепь ГРМ
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            точный размер и плавный высокоскоростной переход
                        </h4>
                    </div>
                </div>
            </div>
            <div class='carousel-item position-relative' data-bs-interval='7000'>
                <img src='../../assets/images/silent.webp' class='hero-img d-block w-100'
                     alt='Мотоцикл с бесшумной цепью'>
                <div class='carousel-container position-absolute top-50 translate-middle banner'>
                    <div>
                        <h2 class='carousel-item-title fw-bold text-white pt-5'>
                            <div class='separator bg-warning mb-3'></div>
                            Бесшумная цепь
                        </h2>
                        <h4 class='carousel-item-description text-white mb-3 d-none d-md-block'>
                            низкая вибрация, низкий уровень шума, легирование штифтов, низкий коэффициент трения
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <button class='d-none d-md-block carousel-control-prev' type='button' data-bs-target='#carouselExampleIndicators'
                data-bs-slide='prev'>
            <span class='carousel-control-prev-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Previous</span>
        </button>
        <button class='d-none d-md-block carousel-control-next' type='button' data-bs-target='#carouselExampleIndicators'
                data-bs-slide='next'>
            <span class='carousel-control-next-icon' aria-hidden='true'></span>
            <span class='visually-hidden'>Next</span>
        </button>
    </div>
</template>

<style lang='scss'>
.carousel {
    .carousel-item {
        height: 200px;

        .carousel-container {
            width: 300px;
            left: 180px;
        }

        .carousel-item-title {
            font-size: 20px;
        }
        .carousel-item-description {
            font-size: 16px;
        }
    }

    .carousel-indicators {
        width: 100%;
        margin: 0;
        bottom: -24px;

        .custom-indicator {
            margin: 0;
            width: 100%;
            height: 14px;
            transition: background-color .3s ease-in;

            &.active {
                background-color: #f18200;
            }

        }

    }

    .banner {
        padding: 70px 0;
    }

    .separator {
        width: 100px;
        height: 5px;
    }

    .hero-img {
        height: 100%;
        filter: brightness(0.75);
        object-fit: cover;
    }
    @media screen and (min-width: 768px) {
        .carousel-item {
            height: 300px;

            .carousel-container {
                width: 550px;
                left: 400px;
            }
            .carousel-item-title {
                font-size: 20px;
            }
            .carousel-item-description {
                font-size: 18px;
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .carousel-item {
            height: 450px;
            .carousel-container {
                width: 700px;
                left: 550px;
            }
            .carousel-item-title {
                font-size: 30px;
            }
            .carousel-item-description {
                font-size: 24px;
            }
        }
    }
}
</style>