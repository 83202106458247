import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/ContactsView.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("../views/AboutView.vue"),
    },
    {
        path: "/accreditations",
        name: "accreditations",
        component: () => import("../views/AccreditationsView.vue"),
    },
    {
        path: "/facilities",
        name: "facilities",
        component: () => import("../views/FacilitiesView.vue"),
    },
    {
        path: "/motorcycle-chain",
        name: "motorcycle-chain",
        component: () => import("../views/MotorcycleChainView.vue"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
