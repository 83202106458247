<template>
    <div class='expertise container-xl py-5'>
        <div class='row row-cols-1 gy-5 gy-md-0 row-cols-md-2 justify-content-between'>
            <div class='col-12 col-md-6'>
                <div class='expertise-img-container'>
                    <img src='../../assets/images/expertise/motorcycle-chain.jpg' class='expertise-img'>
                </div>
            </div>
            <div class='col-12 col-md-6 d-lg-flex flex-lg-column justify-content-center align-items-center px-4'>
                <p class='expertise-text mb-3 p-0'>
                    Мотоциклетные цепи SFR – это неотъемлемая часть нашей компании, с месячным объемом производства
                    более 2,2 миллиона единиц.
                </p>
                <p class='expertise-text mb-3 p-0'>
                    Мы гордимся тем, что являемся одним из ведущих производителей
                    мотоциклетных цепей в мире, обслуживая крупнейшие бренды в автомобильной отрасли. В нашем
                    разнообразном ассортименте всегда найдется подходящая цепь для вашего мотоцикла, будь то городской
                    мопед или мощный гоночный байк, мотокросс или квадроцикл ATV.
                </p>
                <p class='expertise-text m-0 p-0'>
                    Скачайте наш каталог, чтобы узнать больше о каждой серии и выбрать наиболее подходящую цепь
                    для вашего мотоцикла. Мы стремимся сделать ваш выбор максимально удобным и будем поддерживать Вас на
                    каждом этапе.
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.expertise {
    .expertise-img-container {
        .expertise-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .expertise-text {
        font-size: 16px;
        line-height: 1.7;
        text-align: justify;
    }
    @media screen and (min-width: 768px) {
        .expertise-img-container {
            height: 500px;
        }
    }
}
</style>