<template>
    <section class="map container-xl py-5 my-5">
        <yandex-map :settings="settings" :coords="[52.289588, 104.280606]" :zoom="10">
            <ymap-marker
                :coords="coords"
                marker-id="123"
                hint-content="По любым вопросам: +8 800 444 53 42"
            />
        </yandex-map>
    </section>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps"

export default {
    components: { yandexMap, ymapMarker },
    data: () => ({
        coords: [
            52.289588, 104.280606
        ],
        settings: {
            apiKey: "2f2706dc-0f45-487c-93c6-029d0a00e46a",
            lang: "ru_RU",
            coordorder: "latlong",
            enterprise: false,
            version: "2.1"
        }
    })
}
</script>

<style lang="scss">
.map {
    .ymap-container {
        height: 400px;
    }
}
</style>
