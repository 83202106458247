<template>
    <!-- WELCOME -->
    <div class="welcome container-xl py-5">
        <div class="row row-cols-1 row-cols-lg-2 justify-content-between">
            <div class="col-12 col-lg-6">
                <h2 class="welcome-title text-warning mt-4 mb-4">Добро пожаловать</h2>
                <p class="welcome-text mb-4">С момента своего основания SFR Chain Group продвигается к лидирующим
                    позициям в
                    индустрии
                    промышленных
                    цепей.</p>
                <p class="welcome-text mb-4">Благодаря строгому контролю качества мы всегда стремимся оправдать доверие
                    наших
                    клиентов.
                    Наша продукция продается более чем в 80 странах во всех отраслях промышленности, где требуется
                    перемещение, транспортировка, подъем или энергопередача.</p>
                <a href="https://sfrchain.com" target="_blank">
                    Узнайте больше об SFR Group
                </a>
            </div>
            <div class="col-12 col-lg-5 offset-lg-1">
                <div class="bg-light">
                    <form v-if="!isChecked" ref="form"  id="contactForm" class="p-4" @submit.prevent="submitForm">
                        <p class="welcome-text p-2 bg-warning text-white text-center"> Для оптовых клиентов </p>
                        <div class="row g-3 mb-4">
                            <div>
                                <input required type="text" v-model="name" class="form-control" id="nameInput"
                                       placeholder="Имя">
                            </div>
                            <div>
                                <input required type="email" v-model="mail" class="form-control" id="mailInput" placeholder="Почта">
                            </div>
                            <div>
                                <input required  type="text" v-model="region" class="form-control" id="regionInput"
                                       placeholder="Регион / город">
                            </div>

                            <div class="form-text mt-4 text-center">
                                Мы не передаем информацию третьим лицам
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="input-group">
                                <textarea id="messageInput" v-model="message"  class="form-control" aria-label="With textarea"
                                          placeholder="Сообщение"></textarea>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-warning text-white px-4 rounded-0" @click="submitForm">Отправить</button>
                        </div>
                        <p v-if="isChecked" class="text-white bg-danger p-3">Заполните все поля</p>

                        <div class="form-text mt-3 text-center">
                            Мы не занимаемся рассылкой. <br> Выходим на связь, когда это нужно Вам.
                        </div>
                    </form>
                    <div v-if="isChecked" class="text-center p-5">
                        <h3> Отлично! <br> Мы свяжемся с Вами в ближайшее время!</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"

const form = ref(null)
const name = ref("")
const mail = ref("")
const region = ref("")
const message = ref("")
const isChecked = ref(false)
const code = ref('')

function clearForm () {
    setTimeout(() => {
        name.value = ''
        mail.value = ''
        region.value = ''
        message.value = ''
        isChecked.value = false
    }, 7000)
}

async function sendLetter () {
    code.value = 'NOSPAM'
    try {
        const formData = new FormData()
        formData.append('name', name.value)
        formData.append('email', mail.value)
        formData.append('region', region.value)
        formData.append('message', message.value)
        formData.append('code', code.value)

        // eslint-disable-next-line no-unused-vars
        const response = await fetch('mail.php', {
            method: 'POST',
            body: formData,
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error when submitting form: ', error)
    } finally {
        clearForm()
    }
}

function validate() {
    return name.value !== '' && mail.value !== '' && region.value !== '' ? isChecked.value = true : isChecked.value = false
}

async function submitForm () {
    if (validate() && isChecked.value) {
       await sendLetter()
    }
}
</script>

<style lang="scss">
.welcome {
    .welcome-title {
        font-size: 42px;
        font-weight: 800;
    }

    .welcome-text {
        font-size: 16px;
        line-height: 1.7;
        text-align: justify;
    }
}
</style>