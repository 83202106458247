import "./assets/main.scss"

import { createApp } from "vue"
import { createHead } from "@unhead/vue"
import router from "./router"
import App from "./App.vue"

const app = createApp(App)

const head = createHead()

app.use(head)
app.use(router).mount("#app")

import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/offcanvas"
